import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

class WhitelistService {
    getWhitelist() {
        return [
            "0xb6865b4787c8896faeb73c16Ca60bFd14c662189",
            "0x9756a36157B0158ea6aBDf78c32253845934BA08",
            "0x8D9A6a135FB05e74881a5049b8897b6a1fC9E63f",
            "0x9bcC9dd6aA1bC488BeDF86240C77297Eb85Cbb73",
            "0x2173d48d33C307DB04e55D2e6B26bF10b4a3b1f7",
            "0x504AeBEd6D89B549229a9Ec9BFc65Ef332bE91d0",
            "0xe62090508211F72aF78AD35EbE0D05d1C972d4aB",
            "0xc2c2Ba1435362d6dCf8A030130b3038c5A9Eb4c7",
            "0x9D7a3F970Bbc7aB9C8537dc9637051b824A9eD0C",
            "0x6cd34abc387790be07abd6aa6aae59681e5e2099",
            "0xA4E3E6917DA1067f917Ac976b26bc1202e9DfEBd",
            "0xd965c0bE4403D0301fE15b3e0b16c7FB9E82072f",
            "0xF9e3d2eEdf461fbFF819cB81184c07dae0E9C839",
            "0xB6B31f5aF88e846999ab683Dfe084524A708c4Dd",
            "0x2cf1e5FAec75560155546E5cF77a4D1d9Ee61861",
            "0x812eBDA0B96281a398559B4E02A7474F7dfAb0C1",
            "0x430543013c44A896Bf67c59c8Faa5F49313f3F38",
            "0xecBF2D79797c0aDfDC790A4d6183422c5d48Fa24",
            "0x2D92b26c68757Ac7bF01D636C6cF40448bfB59AD",
            "0x81C4F8652574B2c878b24c6D7263a44030271E60",
            "0xF39fBf3BD9F7BFDA4FD5Cb30DF587C9F6d6abbBE",
            "0xbFCf0663Ec8eAbd2090Fdcb36534fc8352BDc042",
            "0x9d9051d61e0cA877C2129eeE3148784cDC30cA7e",
            "0x04764F07542f8870168236dA61d31c217c4CBC56",
            "0x716A22ebEBf87e2db3B383c932b8D1430c3Bdb14",
            "0x380bB59A88e746bE3893f2e077B051a7d4B04AFE",
            "0x89388AF2C0D01596dC60cb2C00Ce8588A5224C6F",
            "0xf8aD3F88B0E0d177aa8c5E6BE1E13410FD41CDc7",
            "0x8983ad6D63D7AB3701D74E1E72Fd9DaDF113F3F9",
            "0xD0F061A500B0eBbfC60f563a53710013Ef0470F2",
            "0x4614A6Cf30039003398D8c6cC63B41d217C07B58",
            "0x8852726b19E74902D397bE0486719D1A9D265308",
            "0xE5AE91c6267f22D1F5AA50aC953025a7A36ed36B",
            "0x1A6ECFC7397805e82a95c2E96F175FA4f46F604C",
            "0xb8a62C9B88394141398b89E8c1BC8b7793254A4D",
            "0x3844D476Af45580f654aA9c317376ae4754D63dB",
            "0x5CF24F62bCc2fAfA056C8d1429bC799400e285ab",
            "0xB09736d869F5a00fC1FC85E7AACb94AD3Ab383Ba",
            "0xE8C38d7793631eca6B9AB0e975A9a284f46Fdee8",
            "0xFb2921F42525eA871A05B23BD0Ec386Df2Bb3b8E",
            "0x9081E7ad50188dF1589B8b9Fb7ad0f26E3b3028A",
            "0x63A715B6651549a87A494125dC7379400e6Ee0b0",
            "0x8E6136Ce3572D5C9be30b06b0bC1838F36A16f21",
            "0x03AF94D788a17D00691eceC60dD10EBbc41212A3",
            "0x638Fc3311ECa6FF6514db82705dc1C7D93fA7D6C",
            "0xCc4CeD15D2f94bDdde727f4F6034a3DFA5C77DFd",
            "0x3eA1Df217D8e2C3B8Bcc58977b90abE6fe00A1ea",
            "0x433D50DC5C32f7ADD0CEC6D3D493B31f387DCA0D",
            "0x5D37F453c005338758b6B6789184a343Df35228e",
            "0xDD80C4b4A220bC7FfbE1AC5269841D0e6abcAB3f",
            "0x66f0b8003010bC8FC592879eF700509d937f5423",
            "0x4f00AAF040b47fB5c98b87ea2FEc25c55161A850",
            "0x62Fc045c0795eEeeA2be28d1Ae0554C589AC70AB",
            "0x00c7A23058E32eFFe114E99623B0Df28861527D4",
            "0x9d99411FA69be2325D8542008D6F668f545a4A83",
            "0xC9CcbD6bf4cfFB24bc661Be42e2901e864dDE0a4",
            "0x0E6985088DC1C284296712865490cD92A5CFdFFE",
            "0xBF9B7422F5E37135899dc1F4AfA865F8E7b84182",
            "0x7757f66A7940D934FE479903438A0877b7bF74cC",
            "0xe518Ad3fBb62C6771E8349Af7Cd666029786aa0c",
            "0xD13D41e71DE8b7B66a8b6a9Ba08AC8BBcaDCa3f5",
            "0xD5e2361F5a3AA333c84f9E14e20c3827AA4E8B55",
            "0x29Ce78dc0F2a5B40dAC18768C25f86E4199f3f1E",
            "0x413a45A15d3C806784C1a177134b1a925E751686",
            "0xD6ed4fB9CB1a94B5e10fB76f31382D8D03Aede4D",
            "0x90F7ad6CA634B2fd8d14BF87a2a6e62bc17deF49",
            "0xe2F3B74fcbC0567a1BaEE792852D756B9e75Bd07",
            "0xe4Bf2fe4D57a5fd0D3086b93982df5Ae5D69F58c",
            "0x9675b8c97d377d28c11fa7e3a147946b758caa15",
            "0xEaA1cd78775c63E21dA4A11F29d8aC4D29989Ce0",
            "0x63444CA340872515064d8823AeE79f723449eB13",
            "0x8A83C0612fcf5164A2CBCEB1ED2E237004a4B930",
            "0x943b58912ffC9667c93F3F70e08A797F7CfB6d9e",
            "0x007E4E679E14F17D62607418F546E2d97d9e5A61",
            "0xF39fBf3BD9F7BFDA4FD5Cb30DF587C9F6d6abbBE",
            "0xA7b030B429B16863887bB9116E830dAE67f41180",
            "0x22cBf92936f171E073350eB771d6F0c8B38330E0",
            "0x7115a7F5B1E2bEc858E1c2D00947FFF9092bb5Fd",
            "0x8983ad6D63D7AB3701D74E1E72Fd9DaDF113F3F9",
            "0x5CF24F62bCc2fAfA056C8d1429bC799400e285ab",
            "0x4614A6Cf30039003398D8c6cC63B41d217C07B58",
            "0xAD7EAe7B0912ed13161f8834eFbc3D92E16193DE",
            "0xe4Bf2fe4D57a5fd0D3086b93982df5Ae5D69F58c",
            "0xa9A0Ac2b50cE6F166d169c3D3178F94251ec23C4",
            "0x3291Af1E080de883fF83a647105D0262C52fBB8c",
            "0x007E4E679E14F17D62607418F546E2d97d9e5A61",
            "0x7315F042F6dA0D901a59D4C4428669fb253C94Ed",
            "0xd19198d30635443F15b29c716C2B6c73257526Fc",
            "0x4cD012d8Aa7E275781060E08ABe164B036216C6a",
            "0x5f54e45414519A3C7bc60936480cf93f50e74A8c",
            "0xEaaBB6418d2ea99ecA981a32c2a2132463b6b194",
            "0x816eb4A49d4F5C422bA6B9bae4d9f605EDBE99FB",
            "0x413a45A15d3C806784C1a177134b1a925E751686",
            "0x68cdDb8E93E558c0428a68B4D5cE943C26503835",
            "0x413a45A15d3C806784C1a177134b1a925E751686",
            "0xa9A0Ac2b50cE6F166d169c3D3178F94251ec23C4",
            "0x15Ca4ED8346596D7BD93114dDC1C3017424c914e",
            "0x75e41101ea6f08019CFDd8182E01d52986Fa6704",
            "0x733a179ad13BE6A797AdB482C94C71025ce7E1A5",
            "0xD0Cb41a92bbE7d75500660d037206b47a310556E",
            "0x0262c71Cc717Ec617190424D99a5F73DF54fDB8b",
            "0xdc52ca73d03D041f27ec98B778261117d323ed5D",
            "0x5B7D021869b262312DBD3ED36222F7240B14cEe2",
            "0xc8ac8C87d6740E382CD214041035e829968ADD7E",
            "0xD078838FDA1BcDAC629b3eA9F9E0763cc1eEAc44",
            "0xe151449431812312BaC68bE89f7550041a768e28",
          ];
    }
    getMerkleTree(list) {
        return new MerkleTree(list, keccak256, { hashLeaves: true, sortPairs: true });
    }
    getWhitelistRoot() {
        const list = this.getWhitelist();
        const tree = this.getMerkleTree(list);
        return tree.getHexRoot();
    }
    getProof(address) {
        const list = this.getWhitelist();
        const tree = this.getMerkleTree(list);
        const leaf = keccak256(address)
        const proof = tree.getHexProof(leaf)
        return proof;
    }
}


export default new WhitelistService();