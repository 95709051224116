import collectableService from '../../services/collectable';

export default {
  getOwner: async({ commit, dispatch }) => {
    collectableService.getOwner()
      .then(res => commit('setOwner', { owner: res}))
      .catch(e => commit('setErrorMsg', e))
  },
  getProvenanceHash: async({ commit, dispatch }) => {
    collectableService.getProvenanceHash()
      .then(res => commit('setProvenanceHash', { provenanceHash: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  setTotalSupply: async({ commit }) => {
    collectableService.getTotalSupply()
      .then(res => commit('setTotalSupply', {totalSupply: res}))
      .catch(e => commit('setErrorMsg', e))
  },
  transferOwnership: async({ commit, dispatch }, { newOwner }) => {
    collectableService.transferOwnership(newOwner)
      .then(res => commit('setOwner', res))
      .catch(e => commit('setErrorMsg', e))
  },
  setBaseURI: async({ commit, dispatch }, { newBaseURI }) => {
    collectableService.setBaseURI(newBaseURI)
      .then(res => commit('setBaseURI', { baseURI: newBaseURI }))
      .catch(e => commit('setErrorMsg', e))
  },
  getBaseURI: async({ commit, dispatch }) => {
    collectableService.getBaseURI()
      .then(res => commit('setBaseURI', { baseURI: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  setProvenanceHash: async({ commit, dispatch }, { provenanceHash }) => {
    collectableService.setProvenanceHash(provenanceHash)
      .then(res => commit('setProvenanceHash', { provenanceHash }))
      .catch(e => commit('setErrorMsg', e))
  },
  getWhitelist: async({ commit, dispatch }) => {
    collectableService.getWhitelist()
      .then(res => commit('setWhitelist', { whitelist: res }))
      .catch(e => commit('setErrorMsg', e))
  },
  changeWhitelist: async({ commit, dispatch }, { whitelist }) => {
    collectableService.setWhitelist(whitelist)
      .then(res => commit('setWhitelist', { whitelist }))
      .catch(e => commit('setErrorMsg', e))
  },
  withdraw: async({ commit, dispatch }) => {
    collectableService.withdraw()
  },
  isTheOwner: async ({commit}, {}) => {
    collectableService.isTheOwner()
      .then(res => commit('setIsTheOwner', { isOwner: res }))
      .catch(e => commit('setErrorMsg', e))
  }
}
