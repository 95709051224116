import Collectable from '../../build/contracts/CrazyBaboonz.json';
import wallet from './wallet';

const networks = {
  1: 'Mainnet',
  3: 'Ropsten',
  4: 'Rinkeby',
  5: 'Goerli',
  42: 'Kovan',
}

const PriceSale = 40000000000000000; // In Wei
const PricePreSale = 20000000000000000; // In Wei

const CollectableContractABI = {
  async getContractInstance() {
    if (window.CollectableInstance) {
      return window.CollectableInstance;
    } else {
      // Get contract instance
      // Get Network ID where contract is deployed
      // This will get you the contract address
      const networkId = await window.web3.eth.net.getId();
      const contractNetwork = Collectable.networks[networkId];
      if (!contractNetwork) {
        throw new Error(`Contract not found - Check that you are in the correct network, network detected: ${networks[networkId]}`);
      }
      const CollectableInstance = new window.web3.eth.Contract(
        Collectable.abi,
        process.env.NFT_CONTRACT_ADDRESS || contractNetwork.address,
      );
      window.CollectableInstance = CollectableInstance
      return window.CollectableInstance;
    }
  },
  async claimNfts(address) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.mintDev(address).send({
      from: wallet.address,
    });
  },
  async mintNfts(quantity) {
    const ether = quantity * PriceSale
    const CollectableInstance = await this.getContractInstance();
    console.log("wallet.address inside mintNft function in collectable.js is", wallet.address)
    let gas
    try {
      gas = await CollectableInstance.methods.mint(quantity).estimateGas({ from: wallet.address, value: ether, gas: 900000 })
      console.log('Estimated gas: ', gas);
    } catch(e) {
      console.log('Error estimating gas: ', e);
      gas = 805185
    }

    return CollectableInstance.methods.mint(quantity).send({
      from: wallet.address,
      value: ether,
      gas
    });
  },
  async mintWhitelist(numNFTs, proof) {
    const ether = numNFTs * PricePreSale
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.mintWhitelist(numNFTs, proof).send({
      from: wallet.address,
      value: ether
    });
  },
  async mintNFTPresale(numNFTs) {
    const ether = numNFTs * PricePreSale
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.mintPreSale(numNFTs).send({
      from: wallet.address,
      value: ether
    });
  },
  async getTotalSupply() {  
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.totalSupply().call();
  },
  async isTheOwner(){
    const CollectableInstance = await this.getContractInstance();
    const ownerAddress = await CollectableInstance.methods.owner().call();
    return ownerAddress === wallet.address
  },
  async getOwner(){
    const CollectableInstance = await this.getContractInstance();
    const ownerAddress = await CollectableInstance.methods.owner().call();
    return ownerAddress;
  },
  async withdraw() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.withdraw().send({
      from: wallet.address,
    });
  },
  async transferOwnership(newOwner) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.transferOwnership(newOwner).send({
      from: wallet.address,
    });
  },
  async setBaseURI(newBaseURI) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.setBaseURI(newBaseURI).send({
      from: wallet.address,
    });
  },
  async getBaseURI() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.getBaseURI().call();
  },
  async setProvenanceHash(provenanceHash) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.setProvenanceHash(provenanceHash).send({
      from: wallet.address,
    });
  },
  async setWhitelist(whitelist) {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.setWhitelist(whitelist).send({
      from: wallet.address,
    });
  },
  async getWhitelist() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.WHITELIST().call();
  },
  async getProvenanceHash() {
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.methods.CRAZY_BABOONZ_PROVENANCE_HASH().call();
  },
  async getHolders(){
    const CollectableInstance = await this.getContractInstance();
    return CollectableInstance.getPastEvents('Transfer', {from: CollectableInstance._address })
      .then(events => {
        let holders = {}
        events.forEach(event => {
          if( holders[event.returnValues.to] ){
            holders[event.returnValues.to].push(event.returnValues.tokenId)
          } else {
            holders[event.returnValues.to] = [event.returnValues.tokenId]
          }          
        }) 
        return holders
      })
  }
}
export default CollectableContractABI;
