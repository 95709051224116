export default {
  collectable: {
    owner: "",
    baseURI: "",
    provenanceHash: "",
    whitelist: "",
    isOwner: false,
    saleState: false,
    preSaleState: false,
    preSaleState: false,
    totalSupply: 0
  },
  errorMsg: ""
}
