export default {
  setErrorMsg(state, error){
    let message = error.message || 'Error please try latter';
    if(message === "Cannot read property 'net' of undefined") {
      state.errorMsg = "Check that you are in the correct network";
    } else {
      state.errorMsg = message;
    }
  },
  emptyCollection(state){
    state.errorMsg = "";
    state.collectable = {
      baseURI: "",
      whitelist: "",
      isOwner: false,
      saleState: false
    }
  },
  setIsTheOwner(state, { isOwner }){
    state.errorMsg = "";
    state.collectable.isOwner = isOwner;
  },
  setOwner(state, { owner }){
    state.errorMsg = "";
    state.collectable.owner = owner;
  },
  setBaseURI(state, { baseURI }){
    state.errorMsg = "";
    state.collectable.baseURI = baseURI;
  },
  setProvenanceHash(state, { provenanceHash }){
    state.errorMsg = "";
    state.collectable.provenanceHash = provenanceHash;
  },
  setWhitelist(state, { whitelist }){
    state.errorMsg = "";
    state.collectable.whitelist = whitelist;
  },
  setTotalSupply(state, { totalSupply }) {
    state.errorMsg = "";
    state.collectable.totalSupply = totalSupply;
  },
}
